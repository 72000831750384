<template>
  <div>
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">{{ $route.params.id ? 'View' : 'Create New' }} Rate</h4>
      </CCol>
      <CCol sm="8"></CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow>
            <CCol sm="12">
              <validation-observer
                  ref="formValidator"
                  v-slot="{ handleSubmit }"
              >
                <form
                    v-on:submit.prevent="handleSubmit(storeRate)"
                    class="p-4"
                >
                  <div v-if="serverErrors">
                    <CAlert
                        color="danger"
                        v-for="err in serverErrors"
                        :key="err"
                    >
                      {{ err }}.
                    </CAlert>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
<!--                        <div class="col-md-4">
                          <ValidationProvider
                              vid="wr_code"
                              name="Ward Code"
                              :rules="{required: false }"
                              v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label>Ward Code </label>
                              <input
                                  type="text"
                                  class="form-control"
                                  v-model="rate.wr_code"
                                  :class=" errors.length ? 'is-invalid': ''"/>
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>-->
                        <div class="col-md-3">
                          <ValidationProvider
                              vid="wr_desc"
                              name="Ward Description"
                              :rules="{required: true }"
                              v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label>Ward Description </label>
                              <select @change="changeWardDesc()" v-model="rate.wr_desc" class="form-control"
                                      :class=" errors.length ? 'is-invalid': ''">
                                <option value="">Please select</option>
                                <option v-for="wardDescList in wardDescLists" :value="wardDescList">{{ wardDescList }}
                                </option>
                              </select>
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-3">
                          <ValidationProvider
                              vid="w_rate"
                              name="Ward Rate"
                              :rules="{required: true }"
                              v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label>Ward Rate </label>
                              <input
                                  type="text"
                                  class="form-control"
                                  v-model="rate.w_rate"
                                  :class=" errors.length ? 'is-invalid': ''"/>
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-3">
                          <ValidationProvider
                              vid="w_rate2"
                              name="Ward Rate2"
                              :rules="{required: true }"
                              v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label>Ward Rate2 </label>
                              <input
                                  type="text"
                                  class="form-control"
                                  v-model="rate.w_rate2"
                                  :class=" errors.length ? 'is-invalid': ''"/>
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-3">
                          <ValidationProvider
                              vid="avg_unit"
                              name="Avg Unit"
                              :rules="{required: true }"
                              v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label>Avg Unit </label>
                              <select v-model="rate.avg_unit" :class=" errors.length ? 'is-invalid': ''"
                                      class="form-control">
                                <option value="">Please select</option>
                                <option value="CM">CM</option>
                                <option value="GAL">GAL</option>
                              </select>
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-3">
                          <ValidationProvider
                              vid="unit_desc"
                              name="Unit Desc"
                              :rules="{required: true }"
                              v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label>Unit Desc </label>
                              <select v-model="rate.unit_desc" :class=" errors.length ? 'is-invalid': ''"
                                      class="form-control">
                                <option value="">Please select</option>
                                <option value="CM">CM</option>
                                <option value="1000 G">1000 G</option>
                              </select>
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-3">
                          <ValidationProvider
                              vid="activated"
                              name="Activated"
                              :rules="{required: true }"
                              v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label>Activated </label>
                              <el-date-picker
                                  required
                                  class="w-100 p-0"
                                  v-model="rate.activated"
                                  type="date"
                                  placeholder="Enter Activated date"
                                  :format="$datePickerFormat"
                                  value-format="yyyy-MM-dd"
                              />
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-3">
                          <ValidationProvider
                              vid="deactivated"
                              name="Deactivated"
                              :rules="{required: false }"
                              v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label>Deactivated </label>
                              <el-date-picker
                                  class="w-100 p-0"
                                  v-model="rate.deactivated"
                                  type="date"
                                  placeholder="Enter Deactivated date"
                                  :format="$datePickerFormat"
                                  value-format="yyyy-MM-dd"
                              />
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-3">
                          <ValidationProvider
                              vid="status"
                              name="status"
                              :rules="{required: true }"
                              v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label>status </label>
                              <select v-model="rate.status" :class=" errors.length ? 'is-invalid': ''"
                                      class="form-control">
                                <option value="">Please select</option>
                                <option value="PENDING">PENDING</option>
                                <option value="ACTIVE">ACTIVE</option>
                              </select>
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-3">
                          <ValidationProvider
                              vid="due_date_duration"
                              name="Due Date Duration"
                              :rules="{
                              required: true,
                              numeric: true,
                            }"
                              v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label
                              >Due Date Duration <br/>
                                <small
                                >max. days to pay bill without
                                  surcharge</small
                                ></label
                              >
                              <input
                                  type="number"
                                  class="form-control"
                                  v-model="rate.due_date_duration"
                                  placeholder="Due date duration"
                                  :class="
                                  errors.length
                                    ? 'is-invalid'
                                    : ''
                                "
                              />
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-3">
                          <ValidationProvider
                              vid="vat"
                              name="VAT"
                              :rules="{
                              required: true,
                              regex: /^\d*\.?\d*$/,
                            }"
                              v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label
                              >VAT
                                <br><small>(%) percent</small>
                              </label>
                              <input
                                  type="number"
                                  class="form-control"
                                  v-model="rate.vat"
                                  step="0.01"
                                  :class="
                                  errors.length
                                    ? 'is-invalid'
                                    : ''
                                "
                              />
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-3">
                          <ValidationProvider
                              vid="vat_reg_no"
                              name="VAT Reg. No"
                              :rules="{
                              required: true,
                              min: 6,
                            }"
                              v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label>VAT Reg. No. </label>
                              <input
                                  type="text"
                                  class="form-control"
                                  v-model="rate.vat_reg_no"
                                  :class="
                                  errors.length
                                    ? 'is-invalid'
                                    : ''
                                "
                              />
                              <p class="text-danger mt-0 pt-0">
                                {{ errors[0] }}
                              </p>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label>Show Arrear on Bill?</label>
                            <div class="row">
                              <div class="checkbox col-md-3 pl-4">
                                <label>
                                  <input
                                      type="radio"
                                      name="arrear"
                                      v-model="rate.show_arrear"
                                      :value="true"
                                  />
                                  Yes
                                </label>
                              </div>

                              <div class="checkbox col-md-4">
                                <label>
                                  <input
                                      type="radio"
                                      name="arrear"
                                      v-model="rate.show_arrear"
                                      :value="false"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="col-md-6">
                    <label>Billing Surcharge Setup</label>
                    <table class="table">
                      <thead>
                      <tr>
                        <th>Month No.</th>
                        <th>Charge (%)</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="(val, index) in rate.surcharges"
                          :key="index"
                      >
                        <td>
                          <ValidationProvider
                              :vid="'surcharges.' + index + '.month_no'"
                              name="Month No."
                              :rules="{
                                      required: true,
                                      numeric: true,
                                    }"
                              v-slot="{ errors }"
                          >
                            <select
                                class="form-control"
                                v-model="val.month_no"
                                :class="errors.length ? 'is-invalid' : ''"
                            >
                              <option value="">Select Month No</option>
                              <option
                                  v-for="(newFey, fKey) in 12"
                                  :key="fKey"
                                  :value="newFey"
                              >{{ newFey }}
                              </option
                              >
                            </select>
                            <p class="text-danger mt-0 pt-0">
                              {{ errors[0] }}
                            </p>
                          </ValidationProvider>
                        </td>
                        <td>
                          <ValidationProvider
                              :vid="'surcharges.' + index + '.charge'"
                              name="Charge"
                              :rules="{
                                      required: true,
                                      regex: /^\d*\.?\d*$/,
                                    }"
                              v-slot="{ errors }"
                          >
                            <input
                                type="number"
                                v-model="val.charge"
                                class="form-control"
                                step="0.01"
                                :class="errors.length ? 'is-invalid' : ''"
                            />
                            <p class="text-danger mt-0 pt-0">
                              {{ errors[0] }}
                            </p>
                          </ValidationProvider>
                        </td>
                        <td>
                          <a
                              v-if="index > 0"
                              href="javascript:void(0)"
                              class="text-danger"
                              @click="removeItem(index)"
                          ><i
                              class="fa fa-trash-o fa-lg pt-2"
                              aria-hidden="true"
                          ></i
                          ></a>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <button
                              type="button"
                              class="btn btn-primary btn-sm"
                              @click="addMore()"
                          >
                            + Add More Item
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>

                  <hr/>

                  <div class="row">
                    <div class="col-md-12 text-right">
                      <button type="submit" class="btn btn-primary">
                        {{ $route.params.id ? 'View' : 'Save' }}
                      </button>
                    </div>
                  </div>
                </form>
              </validation-observer>
            </CCol>
          </CRow>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "CreateBillSettlementComp",
  data() {
    return {
      breadcrumbs: [
        {text: "Home", path: "/dashboard", isActive: false},
        {text: "Rates", path: "/bills/rates", isActive: false},
        {text: `${this.$route.params.id ? 'Update' : 'Create'} Rate`, path: "/", isActive: true}
      ],
      rate: {
        wr_code: "",
        wr_desc: "",
        w_rate: "",
        w_rate2: "",
        avg_unit: "",
        unit_desc: "",
        activated: "",
        deactivated: "",
        status: "ACTIVE",

        surcharges: [
          {month_no: 1, charge: 5},
          {month_no: 2, charge: 10},
          {month_no: 3, charge: 15},
        ],
        vat: 15,
        vat_reg_no: "000494029",
        show_arrear: true,
        due_date_duration: 30,
      },
      wardDescLists: [
        'DOM-MET-LITRE',
        'DOM-MET-GALLON',
        'DOM-DEF-LITRE',
        'DOM-DEF-GALLON',
        'NON-MET-LITRE',
        'NON-MET-GALLON',
        'NON-DEF-LITRE',
        'NON-DEF-GALLON'
      ],
      serverErrors: []
    };
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  methods: {
    addMore() {
      this.rate.surcharges.push({month_no: "", charge: ""});
    },
    removeItem(index) {
      this.rate.surcharges.splice(index, 1);
    },
    getRate(id) {
      console.log(id, 'id')
      this.$store.dispatch("Rates/getRate", id).then(({data}) => {
            this.$store.dispatch("Settings/loading", false);
            this.rate = data.data
          }, (error) => {
            this.$store.dispatch("Settings/loading", false);
          }
      );
    },
    changeWardDesc() {
      const text = this.rate.wr_desc;
      if (text && text.includes("LITRE")) {
        this.rate.avg_unit =
            this.rate.unit_desc = "CM"
      } else {
        this.rate.avg_unit = "GAL"
        this.rate.unit_desc = "1000 G"
      }
    },
    storeRate() {
      this.$store.dispatch("Settings/loading", true);
      const rateStoreUpdate = this.$route.params.id ? this.$store.dispatch("Rates/update", this.rate) : this.$store.dispatch("Rates/store", this.rate)

      rateStoreUpdate.then(() => {
            this.$store.dispatch("Settings/loading", false);
            this.$route.params.id ? this.$toastr.s("Rate Successfuly Updated", "Success!") : this.$toastr.s("Rate Successfuly Created", "Success!");
            this.$router.push("/bills/rates");
          }, (error) => {
            this.serverErrors = [];
            if (error.response.data) {
              this.serverErrors.push(error.response.data.message);
              this.$toastr.e(error.response.data.message, "Failed!");
              if (error.response.data.errors) {
                this.$refs.formValidator.setErrors(error.response.data.errors);
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                })
              }
            }
            this.$store.dispatch("Settings/loading", false);
          }
      );
    },
  }
  ,
  mounted() {
    if (this.$route.params.id) {
      this.getRate(this.$route.params.id)
    }
  }
}
;
</script>
<style lang="scss" scoped>
.card {
  border: none;
  padding: 15px 15px 0;
  color: #000;

  label {
    font-weight: 600;
  }
}

div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
}

#CAppTab {
  .nav-item {
    .nav-link {
      color: #000 !important;
      font-weight: 600;
    }
  }
}
</style>
