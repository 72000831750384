var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"4"}},[_c('h4',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.$route.params.id ? 'View' : 'Create New')+" Rate")])]),_c('CCol',{attrs:{"sm":"8"}})],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"p-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.storeRate)}}},[(_vm.serverErrors)?_c('div',_vm._l((_vm.serverErrors),function(err){return _c('CAlert',{key:err,attrs:{"color":"danger"}},[_vm._v(" "+_vm._s(err)+". ")])}),1):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"vid":"wr_desc","name":"Ward Description","rules":{required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Ward Description ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.rate.wr_desc),expression:"rate.wr_desc"}],staticClass:"form-control",class:errors.length ? 'is-invalid': '',on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.rate, "wr_desc", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.changeWardDesc()}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Please select")]),_vm._l((_vm.wardDescLists),function(wardDescList){return _c('option',{domProps:{"value":wardDescList}},[_vm._v(_vm._s(wardDescList)+" ")])})],2),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"vid":"w_rate","name":"Ward Rate","rules":{required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Ward Rate ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rate.w_rate),expression:"rate.w_rate"}],staticClass:"form-control",class:errors.length ? 'is-invalid': '',attrs:{"type":"text"},domProps:{"value":(_vm.rate.w_rate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rate, "w_rate", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"vid":"w_rate2","name":"Ward Rate2","rules":{required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Ward Rate2 ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rate.w_rate2),expression:"rate.w_rate2"}],staticClass:"form-control",class:errors.length ? 'is-invalid': '',attrs:{"type":"text"},domProps:{"value":(_vm.rate.w_rate2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rate, "w_rate2", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"vid":"avg_unit","name":"Avg Unit","rules":{required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Avg Unit ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.rate.avg_unit),expression:"rate.avg_unit"}],staticClass:"form-control",class:errors.length ? 'is-invalid': '',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.rate, "avg_unit", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Please select")]),_c('option',{attrs:{"value":"CM"}},[_vm._v("CM")]),_c('option',{attrs:{"value":"GAL"}},[_vm._v("GAL")])]),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"vid":"unit_desc","name":"Unit Desc","rules":{required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Unit Desc ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.rate.unit_desc),expression:"rate.unit_desc"}],staticClass:"form-control",class:errors.length ? 'is-invalid': '',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.rate, "unit_desc", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Please select")]),_c('option',{attrs:{"value":"CM"}},[_vm._v("CM")]),_c('option',{attrs:{"value":"1000 G"}},[_vm._v("1000 G")])]),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"vid":"activated","name":"Activated","rules":{required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Activated ")]),_c('el-date-picker',{staticClass:"w-100 p-0",attrs:{"required":"","type":"date","placeholder":"Enter Activated date","format":_vm.$datePickerFormat,"value-format":"yyyy-MM-dd"},model:{value:(_vm.rate.activated),callback:function ($$v) {_vm.$set(_vm.rate, "activated", $$v)},expression:"rate.activated"}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"vid":"deactivated","name":"Deactivated","rules":{required: false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Deactivated ")]),_c('el-date-picker',{staticClass:"w-100 p-0",attrs:{"type":"date","placeholder":"Enter Deactivated date","format":_vm.$datePickerFormat,"value-format":"yyyy-MM-dd"},model:{value:(_vm.rate.deactivated),callback:function ($$v) {_vm.$set(_vm.rate, "deactivated", $$v)},expression:"rate.deactivated"}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"vid":"status","name":"status","rules":{required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("status ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.rate.status),expression:"rate.status"}],staticClass:"form-control",class:errors.length ? 'is-invalid': '',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.rate, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Please select")]),_c('option',{attrs:{"value":"PENDING"}},[_vm._v("PENDING")]),_c('option',{attrs:{"value":"ACTIVE"}},[_vm._v("ACTIVE")])]),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"vid":"due_date_duration","name":"Due Date Duration","rules":{
                              required: true,
                              numeric: true,
                            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Due Date Duration "),_c('br'),_c('small',[_vm._v("max. days to pay bill without surcharge")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rate.due_date_duration),expression:"rate.due_date_duration"}],staticClass:"form-control",class:errors.length
                                    ? 'is-invalid'
                                    : '',attrs:{"type":"number","placeholder":"Due date duration"},domProps:{"value":(_vm.rate.due_date_duration)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rate, "due_date_duration", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"vid":"vat","name":"VAT","rules":{
                              required: true,
                              regex: /^\d*\.?\d*$/,
                            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("VAT "),_c('br'),_c('small',[_vm._v("(%) percent")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rate.vat),expression:"rate.vat"}],staticClass:"form-control",class:errors.length
                                    ? 'is-invalid'
                                    : '',attrs:{"type":"number","step":"0.01"},domProps:{"value":(_vm.rate.vat)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rate, "vat", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"vid":"vat_reg_no","name":"VAT Reg. No","rules":{
                              required: true,
                              min: 6,
                            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("VAT Reg. No. ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rate.vat_reg_no),expression:"rate.vat_reg_no"}],staticClass:"form-control",class:errors.length
                                    ? 'is-invalid'
                                    : '',attrs:{"type":"text"},domProps:{"value":(_vm.rate.vat_reg_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rate, "vat_reg_no", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Show Arrear on Bill?")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"checkbox col-md-3 pl-4"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rate.show_arrear),expression:"rate.show_arrear"}],attrs:{"type":"radio","name":"arrear"},domProps:{"value":true,"checked":_vm._q(_vm.rate.show_arrear,true)},on:{"change":function($event){return _vm.$set(_vm.rate, "show_arrear", true)}}}),_vm._v(" Yes ")])]),_c('div',{staticClass:"checkbox col-md-4"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rate.show_arrear),expression:"rate.show_arrear"}],attrs:{"type":"radio","name":"arrear"},domProps:{"value":false,"checked":_vm._q(_vm.rate.show_arrear,false)},on:{"change":function($event){return _vm.$set(_vm.rate, "show_arrear", false)}}}),_vm._v(" No ")])])])])])])])]),_c('hr'),_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v("Billing Surcharge Setup")]),_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Month No.")]),_c('th',[_vm._v("Charge (%)")]),_c('th',[_vm._v("Action")])])]),_c('tbody',[_vm._l((_vm.rate.surcharges),function(val,index){return _c('tr',{key:index},[_c('td',[_c('ValidationProvider',{attrs:{"vid":'surcharges.' + index + '.month_no',"name":"Month No.","rules":{
                                      required: true,
                                      numeric: true,
                                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(val.month_no),expression:"val.month_no"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(val, "month_no", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select Month No")]),_vm._l((12),function(newFey,fKey){return _c('option',{key:fKey,domProps:{"value":newFey}},[_vm._v(_vm._s(newFey)+" ")])})],2),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"vid":'surcharges.' + index + '.charge',"name":"Charge","rules":{
                                      required: true,
                                      regex: /^\d*\.?\d*$/,
                                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(val.charge),expression:"val.charge"}],staticClass:"form-control",class:errors.length ? 'is-invalid' : '',attrs:{"type":"number","step":"0.01"},domProps:{"value":(val.charge)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(val, "charge", $event.target.value)}}}),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('td',[(index > 0)?_c('a',{staticClass:"text-danger",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('i',{staticClass:"fa fa-trash-o fa-lg pt-2",attrs:{"aria-hidden":"true"}})]):_vm._e()])])}),_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.addMore()}}},[_vm._v(" + Add More Item ")])])])],2)])]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-right"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$route.params.id ? 'View' : 'Save')+" ")])])])])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }